import Introduction from './components/utils/introduction';
import More from './components/utils/more';
import ListThemes from './components/utils/listthemes';
import Newsletter from './components/utils/newsletter';
import { useState } from 'react';

import './App.css';

function App() {
  const [message, setMessage] = useState(''); // État pour le message

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target[0].value;

    // Afficher le message immédiatement après le clic sur le bouton
    setMessage('La demande est en cours.. Nous vous enverrons un mail une fois validé !');

    try {
      const response = await fetch('https://api.octoicons.fr/api/beta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      console.log('Response status:', response.status);

      if (response.ok) {
        setMessage('Inscription réussie, merci !');
      } else {
        setMessage('Erreur lors de l\'inscription.');
      }
    } catch (error) {
      console.error('Erreur réseau ou CORS:', error);
    }
  };

  return (
    <div className="App">
      <section className='my-48'>
        <span className='badge montserrat text-primary flex justify-center'>Accès bêta</span>
        <h1 className='barlow lg:text-6xl text-center text-3xl m-auto'>Soyez le<br />Premier arrivé.</h1>
        <p className='montserrat mt-5 text-center'>Du frais arrive très prochainement sur OctoIcons 🐙<br />Premier arrivé, premier servi !</p>
        <form onSubmit={handleSubmit} className='beta-signup-form mt-10'>
          <input type="email" placeholder="Votre email" required className='email-input flex m-auto' />
          <button type="submit" className='hover:bg-primary duration-150 hover:text-white text-primary m-auto mt-5'>S'inscrire</button>
        </form>
        {message && <p className='montserrat mt-5 text-center'>{message}</p>} {/* Affichage du message */}
      </section>

      {/*
      <section className='my-56'>
        <h1 className='barlow text-center lg:text-6xl text-4xl lg:w-2/4 m-auto'>Toutes vos icônes<br/>à porté de main.</h1>
        <p className='text-center montserrat mt-5 m-auto'>Accédez à +10,000 icônes sous 9 thèmes customisables et animables.<br />Tout ça, en quelques clics !</p>

        <div className='flex md:flex-row flex-col lg:gap-10 gap-5 lg:text-left text-center justify-center mt-10 btns lg:mx-0 mx-5'>
          <a href='/discover' className='bg-secondary px-10 py-3 rounded-md montserrat text-black uppercase hover:scale-105 duration-150'>Découvrir</a>
          <a href='/login' className='bg-primary px-10 py-3 rounded-md montserrat text-white uppercase hover:scale-105 duration-150'>Commencer</a>
        </div>
      </section>


      <Introduction />

      <More />

      <ListThemes />

      <Newsletter />
      */}
    </div>
  );
}

export default App;
