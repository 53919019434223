import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import categoryIcons from '../json/categoryIcons.json'; // Importer le fichier JSON
import Lightbox from '../components/utils/lightbox'; // Importer le composant Lightbox
import PremiumLightbox from '../components/utils/premiumlightbox';

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";


const getContrastYIQ = (hexcolor) => {
    const r = parseInt(hexcolor.slice(1, 3), 16);
    const g = parseInt(hexcolor.slice(3, 5), 16);
    const b = parseInt(hexcolor.slice(5, 7), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? '#000000' : '#ffffff';
};

function DiscoverIcons() {
    const navigate = useNavigate();

    const [icons, setIcons] = useState([]);
    const [categories, setCategories] = useState([]);
    const [themes, setThemes] = useState([]);
    const [filters, setFilters] = useState({
        theme: '',
        category: '',
        name: '',
        is_premium: ''
    });
    const [selectedTheme, setSelectedTheme] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const [strokeWidth, setStrokeWidth] = useState(1.5); // État pour la largeur du trait
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [isPremiumLightboxOpen, setIsPremiumLightboxOpen] = useState(false); // Nouvelle variable d'état pour gérer l'ouverture de la lightbox premium
    const [color, setColor] = useColor("#1C1C1C");

    const openLightbox = (icon) => {
        setSelectedIcon(icon);
        if (icon.is_premium === 1) { // Vérifier si l'icône est premium et si l'utilisateur n'est pas premium
            setIsLightboxOpen(false); // Fermer la lightbox par défaut
            // Ouvrir une lightbox différente pour les utilisateurs non premium
            setIsPremiumLightboxOpen(true); // Nouvelle variable d'état pour gérer l'ouverture de la lightbox premium
        } else {
            setIsLightboxOpen(true); // Ouvrir la lightbox normale
        }
    };

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        setSelectedIcon(null);
    };

    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await axios.get('http://localhost:3000/discover', {
                    params: filters
                });
                setIcons(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des icônes:', error);
                if (error.message === 'Erreur 500') {
                    navigate('/server-error'); // Rediriger vers la page d'erreur 500
                }
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('http://localhost:3000/categories');
                setCategories(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories:', error);
                if (error.message === 'Erreur 500') {
                    navigate('/server-error'); // Rediriger vers la page d'erreur 500
                }
            }
        };

        const fetchThemes = async () => {
            try {
                const response = await axios.get('http://localhost:3000/themes');
                setThemes(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des thèmes:', error);
                if (error.message === 'Erreur 500') {
                    navigate('/server-error'); // Rediriger vers la page d'erreur 500
                }
            }
        };

        fetchIcons();
        fetchCategories();
        fetchThemes();
    }, [filters]);

    const handleFilterChange = (key, value) => {
        if (key === 'theme') {
            setSelectedTheme(value);
        } else if (key === 'category') {
            setSelectedCategory(value);
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value
        }));
    };

    const handleAllIcons = () => {
        setFilters({
            theme: '',
            category: '',
            name: '',
            is_premium: ''
        });
    };

    const handleStrokeWidthChange = (width) => {
        setStrokeWidth(width);
    };

    return (
        <section className="flex justify-between container-discover">

            <div className="container-left">
                <h2 className="barlow-discover mb-2">Catégories</h2>

                <button className="button-filter barlow-discover mb-2 w-full" onClick={handleAllIcons}>All</button>

                {themes.map((theme) => (
                    <button 
                        className={`barlow-discover button-filter w-full justify-between flex ${selectedTheme === theme ? 'selected' : ''}`} 
                        key={theme} 
                        onClick={() => handleFilterChange('theme', theme)}
                    >
                        <span>{theme}</span><span>{icons.filter(icon => icon.theme === theme && icon.category === selectedCategory).length}</span>
                    </button>
                ))}



                <h2 className="barlow-discover mt-10">Thèmes</h2>

                <div className='flex'>
                {categories.map((category) => (
                    <button 
                        className={`button-categories ${selectedCategory === category ? 'selected' : ''}`} 
                        key={category} 
                        onClick={() => handleFilterChange('category', category)}
                    >
                        {categoryIcons[category] ? (
                            <div dangerouslySetInnerHTML={{ __html: categoryIcons[category] }} />
                        ) : (
                            <span>Aucune icône disponible</span>
                        )}
                    </button>
                ))}
                </div>

            </div>

            <div className="container-center w-full">
                <div className='flex justify-between items-center'>
                    {filters.theme ? (
                        <h2 className='barlow-discover'>{filters.theme} <span className='number'>({icons.filter(icon => icon.theme === filters.theme).length})</span></h2>
                    ) : (
                        <h2 className='barlow-discover'>Toutes les Icônes <span className='number'>({icons.filter(icon => icon.theme === filters.theme).length})</span></h2>
                    )}

                    <div className="search-bar flex items-center">
                        <input
                            type="text"
                            placeholder="Rechercher une icône.."
                            value={filters.name}
                            onChange={(e) => handleFilterChange('name', e.target.value)}
                            className="w-full searchbar"
                        />
                        <div className='button-searchbar'><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="15" height="15" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                            <path d="M21 21l-6 -6" />
                        </svg></div>
                    </div>
                </div>



                <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-3 mt-10 container-g-icon'>
                    {icons.map((icon) => (
                        <div key={icon.id} className='container-icon hover:scale-105 duration-150 relative' onClick={() => openLightbox(icon)}>
                            {icon.is_premium === 1 && (
                                <span className="badge-icon barlow">PRO</span>
                            )}
                            <div 
                                dangerouslySetInnerHTML={{ __html: icon.code }} 
                                style={{ stroke: color.hex, strokeWidth: strokeWidth }}
                            />
                            <p>{icon.name}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="container-right">
                <div className='module-customise'>
                <h2 className="barlow-discover">Customise</h2>
                <div className='palette-discover'>
                <ColorPicker color={color} onChange={setColor} />
                </div>

                <h3 className="montserrat mt-5">Stroke-Width</h3>
                <div className='stroke-width-buttons mt-2 flex items-center'>
                    <button className='btn-custom button-customize-start' onClick={() => handleStrokeWidthChange(1)}>1</button>
                    <button className='btn-custom button-customize' onClick={() => handleStrokeWidthChange(1.5)}>1.5</button>
                    <button className='btn-custom button-customize' onClick={() => handleStrokeWidthChange(2)}>2</button>
                    <button className='btn-custom button-customize-end' onClick={() => handleStrokeWidthChange(2.5)}>2.5</button>
                </div>
                </div>




                <div className='module-code'>
                <h2 className="barlow-discover">Code</h2>
                </div>


                <div className='module-about'>
                <h2 className="barlow-discover">About</h2>
                <p className='montserrat italic'>Need help? Contact team.</p>
                </div>
            </div>

            {/* Lightbox Component */}
            <Lightbox isOpen={isLightboxOpen} onClose={closeLightbox} icon={selectedIcon} color={color} strokeWidth={strokeWidth} />
            {isPremiumLightboxOpen && ( // Afficher la lightbox premium si l'état est vrai
                <PremiumLightbox isOpen={isPremiumLightboxOpen} onClose={() => setIsPremiumLightboxOpen(false)} />
            )}

        </section>
    );
}

export default DiscoverIcons;