function Error404() {
    return (
      <div>
  
  
        <section className='my-20 lg:mx-24 mx-5 lg:flex'>
        <div className="bloc-atout m-auto">un curseur qui deplace une icone vers un autre bloc ??</div>
        <div className="lg:flex lg:flex-col lg:justify-between">
        <div className="lg:mb-0 mb-5">
        <span className='badge montserrat text-primary lg:mt-0 mt-5 lg:text-left text-center flex lg:justify-start justify-center'>Erreur 404</span>
        <h1 className='barlow lg:text-5xl text-3xl lg:text-left text-center mt-5'>Une erreur est survenue</h1>
        <p className="montserrat lg:text-left text-center mt-2">Octo à voulu aller vite et il est tombé au moment de l'affichage..</p>
        </div>
          
        <a href="/" className="btn-primary montserrat text-primary duration-150 flex justify-center lg:m-0 m-auto">Revenir à l'accueil</a>
        </div>
        </section>
  
      </div>
    );
  }
  
  export default Error404;