import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Loading from './views/loading'; // Import du composant Loading


// Import components & views
import AnnounceBar from './components/announcebar';
import NavBar from './components/navbar';
import Footer from './components/footer';

import App from './App';
import IconsDiscover from './views/discovericon';
/*import Pro from './views/pro';
import Contact from './views/contact';
import ChangeLog from './views/changelog';
import Versions from './views/versions';
import Teams from './views/teams';
import Status from './views/status';*/
import Mentions from './views/mentions';
import Conditions from './views/conditions';
import Error404 from './views/errors/error404';
import Error500 from './views/errors/error500';

const AppRouter = () => {
    const [loading, setLoading] = useState(true); // Démarre le chargement par défaut
    const location = useLocation();

    useEffect(() => {
        const updateLoadingState = () => {
            const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
            if (connection && connection.effectiveType) {
                setLoading(true); // Toujours charger
                const delay = connection.effectiveType === '4g' || connection.effectiveType === 'wifi' ? 500 : 2000; // Délai selon la connexion
                setTimeout(() => setLoading(false), delay); // Arrête le chargement après le délai
            } else {
                // Si aucune connexion n'est détectée, appliquer un délai par défaut
                setTimeout(() => setLoading(false), 1000); // Par exemple, 1 seconde par défaut
            }
        };
    
        updateLoadingState(); // Vérifie l'état de la connexion au chargement
    
        const handleConnectionChange = () => {
            console.log("Connection state changed");
            updateLoadingState(); // Met à jour l'état lors d'un changement de connexion
        };
    
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);
        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, [location]);
    

    if (loading) {
        return <Loading />; // Affiche la page de chargement
    }

    return (
      <>
          <AnnounceBar />
          <NavBar />

        <Routes>
            <Route path="/" element={<App />} />
            <Route path='/discover' element={<IconsDiscover />} />
            {/*<Route path='/plus' element={<Pro />}/>
            <Route path='/contact' element={<Contact />}/>


            <Route path='/changelog' element={<ChangeLog />}/>
            <Route path='/versions' element={<Versions />}/>
            <Route path='/teams' element={<Teams />}/>
            <Route path='/status' element={<Status />}/>*/}
            <Route path='/mentions' element={<Mentions />}/>
            <Route path='/conditions' element={<Conditions />}/>

            <Route path='/server-error' element={<Error500 />} /> {/* Nouvelle route pour l'erreur 500 */}
            <Route path='*' element={<Error404 />} />
        </Routes>

        <Footer />
        </>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>

    <Router>
      <AppRouter />
    </Router>

  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();