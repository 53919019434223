import React, { useState, useRef } from 'react';

const LightboxPremium = ({ isOpen, onClose, icon, iconStrokeColor, strokeWidth }) => {

    return (
        <div className="lightbox-overlay" onClick={onClose}>
            <div className="lightbox-content-premium" onClick={(e) => e.stopPropagation()}>


                <div className='flex justify-between w-full gap-8'>
                    <div className='flex h-full bg-red-500 w-2/3'></div>

                    <div className='flex flex-col justify-between premium my-5'>

                    <div>
                    <h2 className='text-5xl barlow'>Passez au niveau supérieur</h2>
                    <p className='montserrat mt-5'>Avec AllIcons PRO, accédez a tout le pouvoir des icônes !</p>
                    </div>

                    <a href='#' className='bg-premium px-10 py-3 rounded-md montserrat text-white uppercase hover:scale-105 duration-150'>Commencer</a>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" class="absolute mt-2 ml-2 icon icon-tabler icon-tabler-x" onClick={onClose} width="35" height="35" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 6l-12 12" />
                        <path d="M6 6l12 12" />
                    </svg>
                </div>

            </div>
        </div>
    );
};

export default LightboxPremium;