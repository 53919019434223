import React, { useState, useRef, useEffect } from 'react';
import { toPng } from 'html-to-image';

const Lightbox = ({ isOpen, onClose, icon, color = { hex: '#1C1C1C' }, strokeWidth }) => {
    const [copyStatus, setCopyStatus] = useState('Copier');
    const [rotationAngle, setRotationAngle] = useState(0); // État pour gérer l'angle de rotation
    const svgRef = useRef();
    const [svgCode, setSvgCode] = useState(''); // État pour gérer le code SVG

    useEffect(() => {
        if (icon) {
            const updateSvgCode = () => {
                if (icon) { // Assurez-vous que icon est défini
                    const updatedSvg = icon.code
                        .replace(/<svg([^>]*)>/, `<svg$1 stroke="${color.hex}" transform="rotate(${rotationAngle})">`);
                    setSvgCode(updatedSvg); // Met à jour le code SVG
                }
            };
            updateSvgCode();
        }
    }, [icon, color, rotationAngle]); // Dépendances pour mettre à jour le code SVG

    useEffect(() => {
        if (!isOpen) {
            setRotationAngle(0); // Réinitialiser l'angle de rotation à 0 lorsque la Lightbox se ferme
        }
    }, [isOpen]); // Dépendance pour réinitialiser la rotation

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Désactiver le défilement
        } else {
            document.body.style.overflow = 'unset'; // Réactiver le défilement
        }
    }, [isOpen]); // Dépendance pour gérer le défilement

    const setRotation = (angle) => {
        setRotationAngle(angle); // Définir l'angle de rotation
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(svgCode); // Copier le contenu du textarea
        setCopyStatus('Copié!');
        setTimeout(() => setCopyStatus('Copier'), 2000);
    };

    const downloadSvg = () => {
        const svg = svgRef.current;
        const serializer = new XMLSerializer();
        const source = serializer.serializeToString(svg);
        const blob = new Blob([source], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'icon.svg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const downloadPng = () => {
        const svgNode = svgRef.current;
        toPng(svgNode)
            .then((dataUrl) => {
                const a = document.createElement('a');
                a.href = dataUrl;
                a.download = 'icon.png';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => {
                console.error('Error generating PNG:', error);
            });
    };

    const getBadgeColor = (type) => {
        const colors = {
            theme: {
                airplouf: 'bg-purple-300',
                // Add more themes and their corresponding colors
            },
            category: {
                solid: 'bg-blue-200',
                solid: 'bg-green-200',
                // Add more categories and their corresponding colors
            }
        };
        return colors[type][icon[type]] || 'bg-gray-500'; // Default color
    };

    const downloadJpg = () => {
        const svgNode = svgRef.current;
        toPng(svgNode, { quality: 1 })
            .then((dataUrl) => {
                const a = document.createElement('a');
                a.href = dataUrl.replace('image/png', 'image/jpeg'); // Convert to JPG
                a.download = 'icon.jpg';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => {
                console.error('Error generating JPG:', error);
            });
    };

    if (!isOpen) return null;

    return (
        <div className="lightbox-overlay" onClick={onClose}>
            <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>

                <div className='flex flex-col'>
                    <div className='flex gap-10'>

                        <div>
                            <div className="box-icon lightbox-icon">
                                <div
                                    ref={svgRef}
                                    dangerouslySetInnerHTML={{ __html: svgCode }}
                                    style={{ transition: 'transform 0.3s ease' }}
                                />
                            </div>

                            <h3 className='barlow-discover mt-5'>Rotation</h3>
                            <div className='flex items-center mt-2'>
                                <button onClick={() => setRotation(0)} className='btn-custom button-customize-start'>0°</button>
                                <button onClick={() => setRotation(90)} className='btn-custom button-customize'>90°</button>
                                <button onClick={() => setRotation(180)} className='btn-custom button-customize'>180°</button>
                                <button onClick={() => setRotation(270)} className='btn-custom button-customize-end'>270°</button>
                            </div>
                        </div>

                        <div className='flex flex-col justify-between'>
                            <div>
                                <div className='flex justify-between'>

                                    <div className='flex items-center gap-2'>
                                        <h2 className='text-2xl barlow'>{icon.name}</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-link" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#cccccc" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 15l6 -6" />
                                            <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464" />
                                            <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463" />
                                        </svg>
                                    </div>

                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" onClick={onClose} width="35" height="35" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M18 6l-12 12" />
                                        <path d="M6 6l12 12" />
                                    </svg>
                                </div>

                                <div className='flex gap-2 mt-2'>
                                    <span className={`montserrat uppercase rounded-lg px-3 ${getBadgeColor('theme')}`}>{icon.theme}</span>
                                    <span className={`montserrat uppercase rounded-lg px-3 ${getBadgeColor('category')}`}>{icon.category}</span>
                                </div>
                            </div>

                            <h3 className='barlow-discover mt-5'>Code Svg</h3>
                            <div style={{ position: 'relative' }}>
                                <textarea
                                    className='svg-code-ide'
                                    value={svgCode}
                                    readOnly
                                    rows={5}
                                    style={{
                                        backgroundColor: '#1e1e1e',
                                        color: '#ffffff',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        padding: '10px',
                                        fontFamily: 'monospace',
                                        resize: 'none',
                                        width: '500px',
                                        height: '200px'
                                    }}
                                />
                                <button
                                    onClick={handleCopy}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '10px',
                                        backgroundColor: '#4CAF50',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {copyStatus}
                                </button>
                            </div>

                            <h3 className='barlow-discover mt-5'>Autres supports</h3>
                            <div className='flex items-center gap-2'>
                                <button
                                    onClick={downloadSvg}
                                    className='download-lightbox bg-primary hover:bg-primaryhover duration-150'
                                    style={{
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-type-svg" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                                        <path d="M4 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                                        <path d="M10 15l2 6l2 -6" />
                                        <path d="M20 15h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h1v-3" />
                                    </svg>
                                </button>
                                <button
                                    onClick={downloadPng}
                                    className='download-lightbox bg-primary hover:bg-primaryhover duration-150'
                                    style={{
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-type-png" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                                        <path d="M20 15h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h1v-3" />
                                        <path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
                                        <path d="M11 21v-6l3 6v-6" />
                                    </svg>
                                </button>
                                <button
                                    onClick={downloadJpg}
                                    className='download-lightbox bg-primary hover:bg-primaryhover duration-150'
                                    style={{
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-type-jpg" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                                        <path d="M11 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
                                        <path d="M20 15h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h1v-3" />
                                        <path d="M5 15h3v4.5a1.5 1.5 0 0 1 -3 0" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>




                    {/* TODO: Créer un component pour faire le système de mockup selon le thème */}
                    <div className='mt-24'>
                    <h2 className='text-2xl barlow'>Rendu</h2>
                    </div>



                </div>
            </div>
        </div>
    );
};

export default Lightbox;